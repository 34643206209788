<template>
  <v-dialog
    v-model="dialog.display"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
    scrollable
  >
    <v-card tile color="#e8eff5">
      <v-toolbar flat color="grey lighten-5" max-height="64">
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="secondary-font font-weight-medium text-h6">
            {{ dialog.title }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            text
            @click="saveHolidayTime()"
            :disabled="$v.form.$invalid"
            :loading="loading.save"
            >{{ $t("btn.save") }}</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mb-0 pb-0 pt-6" style="height: 400px">
        <v-card class="mx-auto mb-5" max-width="500">
          <v-card-text class="mb-0 pb-3">
            <v-select
              :items="services"
              :label="$t('common.fields.serviceId.title')"
              :placeholder="$t('common.fields.serviceId.placeholder')"
              v-model="form.serviceId"
              :error-messages="errorsServiceId"
              @input="delayTouch($v.form.serviceId)"
              @blur="$v.form.serviceId.$touch"
              item-value="id"
              item-text="name"
            ></v-select>
            <v-text-field
              :label="$t('common.fields.title.title')"
              :placeholder="$t('common.fields.title.placeholder')"
              append-icon="mdi-text-short"
              :error-messages="errorsTitle"
              @input="delayTouch($v.form.title)"
              @blur="$v.form.title.$touch()"
              v-model="form.title"
            ></v-text-field>
            <div class="mb-5">{{ $t("holiday.fields.group.title") }}</div>
            <div v-for="(group, k) in form.groups" :key="k">
              <v-card
                outlined
                class="rounded-lg mb-3"
              >
                <v-card-text>
                  <div class="mb-2">
                    {{
                      $t("holiday.fields.group.description", {
                        group: $utils.pad(k + 1, 2),
                      })
                    }}
                  </div>
                  <v-menu
                    :ref="'menu' + k"
                    v-model="form.groups[k].menu"
                    :close-on-content-click="false"
                    :return-value.sync="form.groups[k].date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-text-field
                          v-model="form.groups[k].date"
                          :label="$t('holiday.fields.date.title')"
                          :placeholder="$t('holiday.fields.date.placeholder')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          @input="
                            delayTouch($v.form.groups.$each.$iter[k].date)
                          "
                          @blur="$v.form.groups.$each.$iter[k].date.$touch()"
                        ></v-text-field>
                        <div
                          class="danger--text mt-2"
                          v-if="$utils.hasProperty(errorsGroups, `${k}.date.0`)"
                        >
                          {{ errorsGroups[k].date[0] }}
                        </div>
                      </div>
                    </template>
                    <v-date-picker
                      v-model="form.groups[k].date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.groups[k].menu = false"
                      >
                        {{ $t("btn.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="saveDate(k, form.groups[k].date)"
                      >
                        {{ $t("btn.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-checkbox
                    v-model="group.recurrent"
                    :label="$t('holiday.fields.group.recurrent')"
                    color="secondary"
                    hide-details
                  ></v-checkbox>
                </v-card-text>
                <v-card-actions v-if="k !== 0">
                  <v-spacer></v-spacer>
                  <v-btn color="danger" small outlined @click="removeDate(k)">{{
                    $t("btn.remove_date")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="addDate()" color="secondary"
              ><v-icon left>mdi-calendar-plus</v-icon
              >{{ $t("btn.add_date") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import DelayTouchMixin from "./../../mixins/form-delay-touch";
import { mapActions } from "vuex";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";

const defaultGroup = {
  menu: false,
  date: null,
  recurrent: false,
};

const defaultForm = {
  serviceId: null,
  title: null,
  groups: [],
};

export default {
  created() {
    this.initForm();
  },
  mixins: [DelayTouchMixin],
  props: {
    services: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          title: null,
        };
      },
    },
    holiday: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    loading: {
      save: false,
    },
    form: Vue._.cloneDeep(defaultForm),
  }),
  validations: {
    form: {
      serviceId: {
        required,
      },
      title: {
        required,
        maxLength: maxLength(100),
      },
      groups: {
        required,
        minLength: minLength(1),
        $each: {
          date: {
            required,
            isDate: (value) =>
              !helpers.req(value) ||
              Vue.moment(value, "YYYY-MM-DD", true).isValid(),
          },
        },
      },
    },
  },
  methods: {
    initForm() {
      if (!this.holiday.id) {
        this.resetForm();
        return;
      }
      const data = this.holiday.data;
      const serviceId = this.holiday.service_id;

      this.form.groups.push(Object.assign({}, defaultGroup));

      this.form.serviceId = serviceId;
      this.form.title = data.title;
      this.form.groups = [];

      for (const group of data.groups) {
        this.form.groups.push({
          date: group.days,
          recurrent: group.recurrent,
        });
      }
    },
    addDate() {
      this.form.groups.push(Object.assign({}, defaultGroup));
    },
    removeDate(k) {
      if (k === 0) return;
      this.form.groups.splice(k, 1);
    },
    saveDate(k, date) {
      const menu = "menu" + k;
      this.$refs[menu][0]["save"](date);
    },
    closeDialog() {
      this.dialog.display = false;
    },
    async saveHolidayTime() {
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      let url = "holidays.create";
      if (this.holiday.id) {
        url = "holidays.update";
      }

      this.loading.save = true;
      try {
        await this.request({
          url: url,
          method: "POST",
          data: this.getFormData(),
          messages: {
            200: this.$t("holiday.edit.success"),
            201: this.$t("holiday.add.success"),
            404: this.$t("holiday.errors.E404"),
            400: true,
            422: (error) => {
              if (error.code === "E100") {
                return this.$t("holiday.errors.E404");
              } else if (error.code === "E101") {
                return this.$t("form_error");
              }
              return this.$t("error_occured");
            },
            500: true,
          },
        });
        if (!this.holiday.id) {
          // reset form
          this.resetForm();
          // close dialog
          this.closeDialog();
        }
        // emit refresh
        this.$emit("refresh");
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    getFormData() {
      const data = {
        service_id: this.form.serviceId,
        data: {
          title: this.form.title,
          groups: [],
        },
      };

      for (const group of this.form.groups) {
        data.data.groups.push({
          days: group.date,
          recurrent: group.recurrent,
        });
      }

      if (this.holiday.id) {
        data.holidayId = this.holiday.id;
      }

      return data;
    },
    resetForm() {
      this.$v.form.$reset();
      this.form = this._.cloneDeep(defaultForm);
      this.form.groups.push(Object.assign({}, defaultGroup));
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  watch: {
    "holiday.id"(val, old) {
      if (!val) {
        if (old) {
          this.initForm();
        }
      } else {
        this.initForm();
      }
    },
  },
  computed: {
    errorsServiceId() {
      const errors = [];

      if (!this.$v.form.serviceId.$dirty) return errors;

      !this.$v.form.serviceId.required &&
        errors.push(this.$t("common.fields.serviceId.required"));

      return errors;
    },
    errorsTitle() {
      const errors = [];

      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("common.fields.title.required"));
      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("common.fields.title.maxLength", {
            max: this.$v.form.title.$params.maxLength.max,
          })
        );

      return errors;
    },
    errorsGroups() {
      const errors = [];

      for (const property in this.$v.form.groups.$each.$iter) {
        errors[property] = { date: [] };
        if (this.$v.form.groups.$each.$iter[property].date.$dirty) {
          !this.$v.form.groups.$each.$iter[property].date.required &&
            errors[property].date.push(this.$t("holiday.fields.date.required"));
          !this.$v.form.groups.$each.$iter[property].date.isDate &&
            errors[property].date.push(this.$t("holiday.fields.date.invalid"));
        }
      }

      return errors;
    },
  },
};
</script>