<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("holiday.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <div class="ml-auto">
          <v-btn color="primary" @click="addHoliday()">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>
                      {{
                        $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </td>
                  <td>{{ item.service.name }}</td>
                  <td>{{ item.data.title }}</td>
                  <td style="min-width: 120px">
                    <template
                      v-if="$permission.$can('holiday.update', ['user', 'service'])"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="editHoliday(item)"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("holiday.btn.edit") }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="$permission.$can('holiday.delete', ['user', 'service'])"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteHoliday(item)"
                            class="ml-2"
                            :loading="loading.delete && holiday.id === item.id"
                          >
                            <v-icon color="primary">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("working_time.btn.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <holiday-form
      :dialog="dialog.form"
      :holiday="holiday"
      :services="services"
      @refresh="refreshItems"
    ></holiday-form>
    <holiday-delete-confirm
      :dialog="dialog.delete"
      @confirm="confirmDeleteHoliday"
    ></holiday-delete-confirm>
  </div>
</template>

<script>
import HolidayDeleteConfirm from "./../commons/confirm.vue";
import HolidayForm from "./form";
import ListMixin from "./../../mixins/list";
import { mapActions } from "vuex";

export default {
  mixins: [ListMixin],
  created() {
    this.setServices();
  },
  data: () => ({
    path: "holidays.list",
    services: [],
    holiday: {},
    dialog: {
      form: {
        display: false,
        title: null,
      },
      edit: {
        display: false,
        title: null,
      },
      delete: {
        display: false,
        message: null,
      },
    },
  }),
  methods: {
    async setServices() {
      try {
        this.services = (
          await this.request({ url: "services.list?limit=50" })
        ).data.data;
      } catch (error) {
        // empty
      }
    },
    addHoliday() {
      this.holiday = {};
      this.dialog.form = {
        display: true,
        title: this.$t("holiday.add.title"),
      };
    },
    editHoliday(item) {
      this.holiday = item;
      this.dialog.form = {
        display: true,
        title: this.$t("holiday.edit.title"),
      };
    },
    deleteHoliday(item) {
      this.holiday = item;
      this.dialog.delete = {
        display: true,
        message: this.$t("holiday.delete.title"),
      };
    },
    async confirmDeleteHoliday() {
      if (!this.holiday.id) return;
      this.loading.delete = true;
      try {
        await this.request({
          url: "holidays.delete",
          data: {
            holidayId: this.holiday.id,
          },
          method: "POST",
          messages: {
            500: true,
            403: true,
            204: this.$t("holiday.delete.success"),
            422: this.$t("error_occured"),
          },
        });
        // refresh list
        this.refreshItems();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("common.fields.created.title"),
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160,
        },
        {
          text: this.$i18n.t("common.fields.serviceId.title"),
          align: "start",
          sortable: false,
          value: "service.name",
          width: 160,
        },
        {
          text: this.$i18n.t("common.fields.title.title"),
          align: "start",
          sortable: false,
          value: "data.title",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120,
        },
      ];
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  components: {
    HolidayForm,
    HolidayDeleteConfirm,
  },
};
</script>