var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-weight-medium text-h6 mb-3"},[_vm._v(" "+_vm._s(_vm.$i18n.t("holiday.title"))+" ")]),_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-start px-4 py-3 flex-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$i18n.t("btn.refresh"))+" ")],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addHoliday()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.$i18n.t("btn.add")))])],1)],1)],1)]),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"160px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"))+" ")])]),_c('td',[_vm._v(_vm._s(item.service.name))]),_c('td',[_vm._v(_vm._s(item.data.title))]),_c('td',{staticStyle:{"min-width":"120px"}},[(_vm.$permission.$can('holiday.update', ['user', 'service']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editHoliday(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("holiday.btn.edit")))])])]:_vm._e(),(_vm.$permission.$can('holiday.delete', ['user', 'service']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"ml-2",attrs:{"x-small":"","icon":"","loading":_vm.loading.delete && _vm.holiday.id === item.id},on:{"click":function($event){return _vm.deleteHoliday(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("working_time.btn.delete")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('holiday-form',{attrs:{"dialog":_vm.dialog.form,"holiday":_vm.holiday,"services":_vm.services},on:{"refresh":_vm.refreshItems}}),_c('holiday-delete-confirm',{attrs:{"dialog":_vm.dialog.delete},on:{"confirm":_vm.confirmDeleteHoliday}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }